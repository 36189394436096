import {
    Box,
    Card,
    DialogActions,
    DialogContent,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, {FC, useEffect, useRef, useState} from 'react';
import {
    EquipmentRequestEntity,
    EquipmentRequestHistoryRecordEntity,
    EquipmentRequestStatusEnum,
    useEquipmentRequestCreateHistoryRecordMutation,
    useGetEquipmentRequestHistoryQuery
} from '../../../../../graphql/graphQlApiHooks';
import {Helmet} from 'react-helmet';
import {StatusChip} from '../ui/StatusChip';
import {Timeline} from '@mui/lab';
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import dayjs from "dayjs";
import {getStatusIcon} from "../../lib/getStatusIcon";
import {getLabelStatusRequest} from "../../lib/getLabelStatusRequest";
import {isCombinedRequest} from "../../lib/isCombinedRequest";
import Iconify from "../../../../../components/Iconify";
import Tooltiper from "../../../../../components/Tooltiper";
import {getStatusColor} from "../../lib/getStatusColor";
import {useTheme} from "@mui/styles";

interface Props {
    equipment: EquipmentRequestEntity;
    onClose: () => void;
}


export const HistoryRequestDialog: FC<Props> = ({equipment, onClose}) => {
    const [message, setMessage] = useState('');
    const timelineRef = useRef<HTMLDivElement>(null);

    const isCombined = isCombinedRequest(equipment)
    const {data, refetch, loading, called} = useGetEquipmentRequestHistoryQuery({
        variables: {
            input: isCombined ? {combinedEquipmentRequestId: equipment.id} : {equipmentRequestId: equipment.id}
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000
    })
    const [equipmentRequestCreateHistoryRecord] = useEquipmentRequestCreateHistoryRecordMutation();

    const history = data?.getEquipmentRequestHistory || []

    const handleAddMessage = () => {
        if (message.trim()) {
            equipmentRequestCreateHistoryRecord({
                variables: {
                    input: {
                        ...(isCombined ? {combinedEquipmentRequestId: equipment.id} : {equipmentRequestId: equipment.id}),
                        message: message
                    }
                }
            }).then(() => {
                refetch()
            })
            setMessage('');
        }
    };


    const previousHistoryLength = useRef(history.length);

    useEffect(() => {
        if (timelineRef.current && !loading && history.length > previousHistoryLength.current) {
            timelineRef.current.scrollTo({
                top: timelineRef.current.scrollHeight + 16,
            });
        }
        previousHistoryLength.current = history.length;
    }, [history, loading]);

    return (
        <>
            <Helmet>
                <title>
                    {equipment?.name
                        ? `История заявки на оборудование | ${equipment?.name}`
                        : 'История заявки на оборудование'}
                </title>
            </Helmet>

            <DialogContent
                dividers
                sx={{
                    bgcolor: "background.default",
                    py: 2,
                    px: 3,
                }}
                ref={timelineRef}
            >

                <EquipmentRequestTimeline
                    history={history as EquipmentRequestHistoryRecordEntity[]}
                    loading={called && loading}
                    requestStatus={equipment?.requestStatus}
                />
            </DialogContent>
            <DialogActions
                sx={{
                    width: '100%',
                    bgcolor: "background.default",
                    py: 2,
                    px: 3,
                }}
            >
                <Paper sx={{
                    p: 2,
                    width: '100%',
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                }}>
                    <TextField
                        placeholder="Сообщение"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        size="small"
                        autoComplete="off"
                        fullWidth
                        variant={"standard"}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleAddMessage();
                            }
                        }}
                        maxRows={3}
                        multiline
                    />

                    <IconButton size={'small'} color="primary" onClick={handleAddMessage}>
                        <Iconify icon={'mingcute:send-fill'} sx={{
                            fontSize: '1.2rem'
                        }}/>
                    </IconButton>
                </Paper>
            </DialogActions>
        </>
    );
};


interface EquipmentRequestTimelineProps {
    history: EquipmentRequestHistoryRecordEntity[];
    loading?: boolean;
    requestStatus?: EquipmentRequestStatusEnum
}

export const EquipmentRequestTimeline: FC<EquipmentRequestTimelineProps> = ({history, loading, requestStatus}) => {
    const theme = useTheme();

    const groupedHistory = history.reduce((acc: {
        status: EquipmentRequestStatusEnum;
        entries: EquipmentRequestHistoryRecordEntity[]
    }[], entry) => {
        const lastGroup = acc[acc.length - 1];

        if (lastGroup && lastGroup.status === entry.requestStatus) {
            lastGroup.entries.push(entry);
        } else {
            acc.push({status: entry.requestStatus, entries: [entry]});
        }

        return acc;
    }, []);

    return (
        <>
            {
                loading ? (
                    <Box>
                        <Timeline position={'right'}>
                            {
                                [...Array(3)].map((_, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineOppositeContent color="text.secondary" fontSize="small" maxWidth="30%">
                                            <Skeleton variant="text" height={40} width={120}/>
                                        </TimelineOppositeContent>

                                        <TimelineSeparator sx={{
                                            opacity: 0.3
                                        }}>
                                            <TimelineDot color="secondary"/>
                                            {index !== 2 && <TimelineConnector/>}
                                        </TimelineSeparator>

                                        <TimelineContent>
                                            <Stack spacing={1}>
                                                <Box display={'flex'} gap={1}>
                                                    <Skeleton variant="text" width={100}/>
                                                    <Skeleton variant="text" width={80}/>
                                                </Box>

                                                <Skeleton variant="rectangular" height={60}/>

                                                {index === 1 && <>
                                                    <Box display={'flex'} gap={1}>
                                                        <Skeleton variant="text" width={100}/>
                                                        <Skeleton variant="text" width={80}/>
                                                    </Box>
                                                    <Skeleton variant="rectangular" height={60}/>
                                                </>}

                                            </Stack>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))
                            }
                        </Timeline>
                    </Box>
                ) : history.length === 0 ? (
                    <Box sx={{minHeight: '400px', display: 'flex', flexDirection: 'column'}}>
                        <Box
                            flex={1}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                flexDirection: 'column',
                                gap: 2
                            }}
                        >
                            <Iconify icon="line-md:chat-off-twotone" sx={{
                                width: 42,
                                height: 42,
                                opacity: 0.3
                            }}/>
                            <Typography variant="body2" color="text.secondary">
                                У этой заявки пока что нет истории
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{height: '100%', minHeight: '400px', overflow: 'auto'}}>
                        <Timeline position="right">
                            {groupedHistory.map((group, groupIndex) => (
                                <TimelineItem key={groupIndex}>
                                    <TimelineOppositeContent color="text.secondary" fontSize="small" maxWidth="30%">
                                        <Stack spacing={1}>
                                            <StatusChip
                                                status={group?.status}
                                                size={"small"}
                                                icon={getStatusIcon(group?.status)}
                                                label={
                                                    <Tooltiper text={getLabelStatusRequest(group?.status)}/>
                                                }
                                            />
                                        </Stack>
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineDot sx={{
                                            backgroundColor: getStatusColor(group?.status, theme).color,
                                        }}/>
                                        {groupIndex !== groupedHistory.length - 1 && <TimelineConnector/>}
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        {group.entries.map((entry, index) => (
                                            <>
                                                <Stack
                                                    spacing={1}
                                                    direction={'row'}
                                                    mt={(!entry?.message || index === 0) ? 0 : 2}
                                                >
                                                    <Typography variant="body2" color="text.primary"
                                                                fontWeight={'bold'}>
                                                        {entry.user.login}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {dayjs(Number(entry.createdAt)).format('DD.MM.YYYY в HH:mm')}
                                                    </Typography>
                                                </Stack>
                                                {entry.message && (<Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        mt: .5,
                                                        ml: 1,
                                                        p: 2,
                                                        position: 'relative',
                                                        overflow: 'initial',
                                                        borderRadius: 3,
                                                        borderTopLeftRadius: 0,
                                                    }}
                                                >
                                                    <svg
                                                        width="9"
                                                        height="20"
                                                        className="svg-appendix"
                                                        style={{
                                                            position: 'absolute',
                                                            left: -9,
                                                            top: -3,
                                                            transform: 'rotate(180deg) scaleX(-1)',
                                                        }}
                                                    >
                                                        <defs>
                                                            <filter x="-50%" y="-14.7%" width="200%" height="141.2%"
                                                                    filterUnits="objectBoundingBox"
                                                                    id="messageAppendix">
                                                                <feOffset dy="1" in="SourceAlpha"
                                                                          result="shadowOffsetOuter1"></feOffset>
                                                                <feColorMatrix
                                                                    values="0 0 0 0 0.643 0 0 0 0 0.639 0 0 0 0 0.643 0 0 0 0.24 0"
                                                                    in="shadowOffsetOuter1"
                                                                ></feColorMatrix>
                                                            </filter>
                                                        </defs>
                                                        <g fill="none" fillRule="evenodd">
                                                            <path
                                                                d="M3 17h6V0c-.193 2.84-.876 5.767-2.05 8.782-.904 2.325-2.446 4.485-4.625 6.48A1 1 0 003 17z"
                                                                fill="#a4a3a4" filter="url(#messageAppendix)"></path>
                                                            <path
                                                                d="M3 17h6V0c-.193 2.84-.876 5.767-2.05 8.782-.904 2.325-2.446 4.485-4.625 6.48A1 1 0 003 17z"
                                                                fill="#FFF" className="corner"></path>
                                                        </g>
                                                    </svg>
                                                    <Typography variant="body2">{entry.message}</Typography>
                                                </Card>)}
                                            </>
                                        ))}
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Box>
                )
            }


        </>
    );
};
