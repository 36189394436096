import React from "react";
import {EquipmentRequestEntity} from "graphql/graphQlApiHooks";
import {useDialog} from "providers/DialogProvider";
import {HistoryRequestDialog} from "../../components/Dialogs/HistoryRequestDialog";

export const useHistoryRequestAction = () => {
    const [dialog, closeDialog] = useDialog();

    const handleHistory = (equipment: EquipmentRequestEntity) => {
        dialog({
            onClose: closeDialog,
            title: "История изменения заявки",
            maxWidth: "sm",
            fullWidth: true,
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            children: <>
                <HistoryRequestDialog
                    equipment={equipment}
                    onClose={() => closeDialog()}
                />
            </>
        });
    };

    return {
        handleHistory
    }
};