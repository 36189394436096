import {
    EquipmentRepairRequestStateObject,
    SportEquipmentEntity,
    useGetSportEquipmentByIdQuery
} from "../../../../graphql/graphQlApiHooks";
import {Card, IconButton, Typography} from "@mui/material";
import Dialog from "components/Dialogs/Dialog";
import React, {useState} from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import dayjs from "dayjs";
import EquipmentInfo from "../../../../components/Dialogs/Titles/EquipmentInfo";
import CardContent from "@mui/material/CardContent";
import {getLabelStatusRepairRequest} from "./TagStatusRepairRequest";
import {BootstrapTooltip} from "../../../../components/BootstrapTooltip";
import {RiChatHistoryLine} from "react-icons/ri";


const HistoryRepairRequest = ({stateHistory, equipment}: { stateHistory: Array<EquipmentRepairRequestStateObject>, equipment: SportEquipmentEntity }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <BootstrapTooltip title={'История заявки'}>
                <IconButton
                    color="inherit"
                    component="span"
                    onClick={() => setOpen(true)}
                >
                    <RiChatHistoryLine
                        style={{
                            fontSize: '18px'
                        }}
                    />
                </IconButton>
            </BootstrapTooltip>


            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                title="История заявки"
                titles={[
                    <EquipmentInfo equipment={equipment as SportEquipmentEntity}/>,
                ]}
            >

                <Timeline position="right">
                    {
                        stateHistory.map((state, index) => {
                            return (
                                <TimelineItem key={index}>
                                    <TimelineOppositeContent
                                        color="text.secondary"
                                        fontSize={'small'}
                                        width={'initial'}
                                        flex={'0 !important'}
                                    >
                                        {
                                            dayjs(state.createdAt).format('DD.MM.YYYY в HH:mm')
                                        }
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        {getLabelStatusRepairRequest(state.status)}
                                        <Card>
                                            <CardContent>
                                                <Typography variant="body2" color="text.secondary">
                                                    {state.comment}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </TimelineContent>
                                </TimelineItem>
                            )
                        })
                    }

                </Timeline>

            </Dialog>
        </>

    )
}

export default HistoryRepairRequest;

