import {
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum,
    UpdateEquipmentRequestInput, useEquipmentRequestCreateHistoryRecordMutation,
    useUpdateEquipmentRequestMutation
} from "graphql/graphQlApiHooks";
import {useTheme} from "@mui/styles";
import {Box, Button, Card, DialogContent, Link, Stack, TextField, Typography} from "@mui/material";
import React, {FC} from "react";
import {useSnackbar} from "notistack";
import {getLabelStatusRequest} from "../../lib/getLabelStatusRequest";
import {Helmet} from "react-helmet";
import Tooltiper from "components/Tooltiper";
import {capitalizedString} from "helpers/capitalizedString";
import {getStatusIcon} from "../../lib/getStatusIcon";
import Iconify from "components/Iconify";
import {alpha, lighten} from "@mui/material/styles";
import {formatPrice} from "helpers/formatPrice";
import DateViewer from "components/DateViewer";
import Settings from "store/settings";
import {FieldDescription} from "../ui/FieldDescription";
import {StatusChip} from "../ui/StatusChip";

interface Props {
    equipment: EquipmentRequestEntity;
    close: () => void;
}

export const RejectRequestDialog: FC<Props> = (props) => {
    const {equipment, close} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [rejectReasonInputValue, setRejectReasonInputValue] = React.useState('');
    const [rejectReasonInputValueError, setRejectReasonInputValueError] = React.useState('');

    const [updateEquipmentRequest] = useUpdateEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests'],
    });

    const [equipmentRequestCreateHistoryRecord] = useEquipmentRequestCreateHistoryRecordMutation();

    const handleSubmit = () => {
        let input: UpdateEquipmentRequestInput;
        if (rejectReasonInputValue === '') {
            enqueueSnackbar('Причина отклонения должна быть указана', {
                variant: "error"
            });
            setRejectReasonInputValueError('Причина отклонения должна быть указана');
            return;
        }

        if (equipment) {
            input = {
                id: equipment?.id,
                name: equipment?.name,
                count: Number(equipment?.count),
                equipmentTypeId: equipment?.equipmentTypeObject?.id as string,
                requestStatus: EquipmentRequestStatusEnum.Rejected,
                rejectReason: rejectReasonInputValue,
                sportObjectId: equipment?.sportObject.id,
                serviceNoteScanId: equipment?.serviceNoteScan?.id,
            }

            updateEquipmentRequest({variables: {input}}).then(() => {
                equipmentRequestCreateHistoryRecord({
                    variables: {
                        input: {
                            requestStatus: EquipmentRequestStatusEnum.Rejected,
                            equipmentRequestId: equipment.id,
                            message: rejectReasonInputValue
                        }
                    }
                }).then(() => {
                    setRejectReasonInputValue('')
                    close();
                })
            })
        }
    };


    const theme = useTheme();
    return (
        <>
            <Helmet>
                <title>
                    {
                        equipment?.name ?
                            `Отклонение заявки на новое оборудование | ${equipment?.name}` :
                            "Отклонение заявки на новое оборудование"
                    }
                </title>
            </Helmet>

            <DialogContent>
                <Box sx={{height: "100%", overflow: "auto", position: "relative"}}>
                    <Box sx={{display: "flex", flexDirection: "column", height: "100%"}} width={'100%'}>

                        <Stack direction={"row"} justifyContent={"space-between"} bgcolor={"background.paper"}
                               gap={'12px'} flexWrap="nowrap">
                            <Box sx={{
                                flexDirection: "column",
                                display: "flex",
                                flexGrow: 1,
                                flexShrink: 1,
                                minWidth: 0,
                            }}>
                                <Box>
                                    <Tooltiper
                                        text={capitalizedString(equipment?.sportSubZone?.name) || "Подзона не указана"}
                                        props={{
                                            variant: "body1",
                                            color: "text.primary",
                                            sx: {fontWeight: 600},
                                        }}
                                    />
                                    <Tooltiper
                                        props={{
                                            variant: "caption",
                                            color: "text.secondary",
                                            sx: {display: "flex", justifyContent: "start"},
                                        }}
                                        text={capitalizedString(equipment?.sportZone?.name)}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', flexGrow: 0, flexShrink: 0,}}>
                                <Box sx={{display: "flex", maxWidth: 200}}>
                                    <Tooltiper
                                        props={{
                                            variant: "caption",
                                            color: "text.secondary",
                                            sx: {textAlign: "right",},
                                        }}
                                        text={capitalizedString(equipment?.sportObject?.name) || "Объект не указан"}
                                    />
                                </Box>
                            </Box>
                        </Stack>


                        <Box
                            width={"100%"}
                            mt={2}
                            sx={{
                                bgcolor: "background.paper"
                            }}
                        >
                            <Stack direction={'row'} spacing={2}>
                                <Box display={'flex'} flexGrow={1} flexShrink={1} minWidth={0}>
                                    <StatusChip
                                        status={equipment?.requestStatus}
                                        size={"small"}
                                        icon={getStatusIcon(equipment?.requestStatus)}
                                        label={getLabelStatusRequest(equipment?.requestStatus)}
                                    />
                                </Box>

                                {equipment?.urgentRequirementComment &&
                                    <Box display={'flex'} flexGrow={0} flexShrink={0}>
                                        <StatusChip
                                            size={"small"}
                                            icon={<Iconify icon="line-md:alert-twotone"/>}
                                            label={'Срочная заявка!'}
                                            customColor={theme.palette.error.main}
                                        />
                                    </Box>
                                }
                            </Stack>

                            <Typography
                                variant={"h2"}
                                color={"text.main"}
                                position={"sticky"}
                                top={0}
                                sx={{
                                    bgcolor: alpha(theme.palette.background.paper, 0.5),
                                    zIndex: 1,
                                    py: 2,
                                    backdropFilter: "blur(10px)",
                                    width: "100%",
                                    mb: 4
                                }}
                            >
                                {equipment?.name}
                            </Typography>


                            <Box sx={{display: "flex", flexGrow: 1, lexDirection: "column", width: "100%"}}>
                                <Stack gap={4} width={'100%'}>

                                    {(equipment?.urgentRequirementComment) &&
                                        <Card elevation={0}>
                                            <Box
                                                sx={{
                                                    px: 2,
                                                    py: 1,
                                                    bgcolor: lighten(theme.palette.error.main, 0.95),
                                                    color: theme.palette.error.main
                                                }}>
                                                <Typography color='inherit' gutterBottom variant="h6" component="div">
                                                    Причина срочной потребности
                                                </Typography>

                                                <Typography color='inherit' variant="body2">
                                                    {equipment.urgentRequirementComment}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    }

                                    <Stack gap={2}>
                                        <FieldDescription
                                            label={"Количество необходимого оборудования"}
                                            value={equipment.count}
                                        />

                                        <FieldDescription
                                            label={"Цена за единицу оборудования"}
                                            value={formatPrice(equipment?.price)}
                                        />

                                        {(!isNaN(equipment?.price * Number(equipment?.count)) && equipment?.price * Number(equipment?.count) > equipment?.price) &&
                                            <FieldDescription
                                                label={"Общая стоимость"}
                                                value={formatPrice(equipment?.price * Number(equipment?.count))}
                                            />}

                                        <FieldDescription
                                            label={"Дата подачи заявки"}
                                            value={
                                                <DateViewer date={equipment.createdAt} showIcon={false}/>
                                            }
                                        />
                                    </Stack>

                                    {(equipment?.equipmentTypeObject || equipment?.sportKindObject) &&
                                        <Card elevation={0} variant={'outlined'}>
                                            <Box sx={{px: 2, py: 1}}>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Характеристики оборудования
                                                </Typography>

                                                <Stack gap={2}>
                                                    {equipment.equipmentTypeObject &&
                                                        <FieldDescription
                                                            label={'Тип оборудования'}
                                                            value={equipment.equipmentTypeObject.name}
                                                        />
                                                    }

                                                    {equipment.sportKindObject &&
                                                        <FieldDescription
                                                            label={'Вид спорта'}
                                                            value={equipment.sportKindObject.name}
                                                        />
                                                    }
                                                </Stack>
                                            </Box>
                                        </Card>
                                    }

                                    {(equipment?.description) &&
                                        <Card elevation={0}>
                                            <Box
                                                sx={{px: 2, py: 1, bgcolor: lighten(theme.palette.primary.main, 0.95)}}>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Дополнительная информация по заявке
                                                </Typography>

                                                <Typography variant="body2">
                                                    {equipment.description}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    }

                                    <Stack gap={2}>
                                        {equipment?.equipmentLink &&
                                            <FieldDescription
                                                label={'Ссылка на оборудования'}
                                                value={
                                                    <Link
                                                        title={equipment.equipmentLink}
                                                        href={equipment.equipmentLink}
                                                        target={"_blank"}
                                                        sx={{
                                                            maxWidth: 200,
                                                            px: 2,
                                                            py: 0.5,
                                                            display: 'inline-flex',
                                                            color: 'primary.main'
                                                        }}
                                                        color={'inherit'}
                                                    >
                                                        <Tooltiper text={equipment.equipmentLink}/>
                                                    </Link>
                                                }
                                            />
                                        }

                                        {equipment?.analogEquipmentLink &&
                                            <FieldDescription
                                                label={'Ссылка на аналогичное оборудование'}
                                                value={
                                                    <Link
                                                        title={equipment.analogEquipmentLink}
                                                        href={equipment.analogEquipmentLink}
                                                        target={"_blank"}
                                                        sx={{
                                                            maxWidth: 200,
                                                            px: 2,
                                                            py: 0.5,
                                                            display: 'inline-flex',
                                                            color: 'primary.main'
                                                        }}
                                                        color={'inherit'}
                                                    >
                                                        <Tooltiper text={equipment.analogEquipmentLink}/>
                                                    </Link>
                                                }
                                            />
                                        }

                                        {equipment?.serviceNoteScan &&
                                            <FieldDescription
                                                label={'Скан служебной записки с потребностью'}

                                                value={
                                                    <Button
                                                        size={"small"}
                                                        component={"a"}
                                                        title={equipment?.serviceNoteScan?.description}
                                                        href={Settings.server + equipment?.serviceNoteScan?.url}
                                                        disabled={!equipment?.serviceNoteScan}
                                                        target={"_blank"}
                                                        sx={{maxWidth: 200, px: 2, py: 0.5}}
                                                        color={'inherit'}
                                                        startIcon={
                                                            <Iconify
                                                                icon={"material-symbols:sim-card-download-outline"}
                                                            />
                                                        }
                                                    >
                                                        <Box display={'inline-flex'}
                                                             width={'calc(100% - 26px)'}><Tooltiper props={{
                                                            sx: {fontWeight: 'bold'}
                                                        }} text={equipment?.serviceNoteScan?.description || 'Скачать'}/></Box>
                                                    </Button>
                                                }
                                            />
                                        }
                                    </Stack>


                                </Stack>
                            </Box>
                        </Box>

                        <TextField
                            label="Причина отклонения"
                            fullWidth
                            required={true}
                            multiline
                            rows={2}
                            value={rejectReasonInputValue}
                            onChange={(e) => {
                                setRejectReasonInputValue(e.target.value);
                                setRejectReasonInputValueError("");
                            }}

                            variant={"filled"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            InputLabelProps={{shrink: true}}

                            error={!!rejectReasonInputValueError}
                            helperText={rejectReasonInputValueError}
                            sx={{my: 4}}
                            autoFocus={true}
                        />
                    </Box>


                </Box>
                <Box
                    display={"flex"}
                    pt={4}
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                        width: "100%",
                        bgcolor: "background.paper",
                    }}
                >

                    <Box flexGrow={1}/>

                    <Button variant="text" color="secondary" size="small" onClick={close}>
                        Закрыть
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={handleSubmit}

                    >
                        Отклонить
                    </Button>
                </Box>
            </DialogContent>
        </>
    )
};

