import React, {FC, MouseEvent, useEffect, useState} from 'react';
import {DataGrid, GridRowId, GridSortModel} from '@mui/x-data-grid';
import {Box, LinearProgress, tableClasses} from '@mui/material';
import {
    CombinedEquipmentRequestEntity,
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum
} from 'graphql/graphQlApiHooks';
import {useEditRequestAction} from "../../hooks/actions/useEditRequestAction";
import {useDeleteRequestAction} from "../../hooks/actions/useDeleteRequestAction";
import {useViewRequestAction} from "../../hooks/actions/useViewRequestAction";
import {styled} from "@mui/styles";
import {darken} from "@mui/material/styles";
import {Footer} from "./components/Footer";
import {useCreateCombinedEquipmentRequestAction} from "../../hooks/actions/useCreateCombinedEquipmentRequestAction";
import {useViewCombineRequest} from "../../hooks/actions/useViewCombineRequest";
import {useEditCombineRequest} from "../../hooks/actions/useEditCombineRequest";
import {useDeleteCombineRequest} from "../../hooks/actions/useDeleteCombineRequest";
import {RequestEntity} from "../../types/RequestEntity";
import {isCombinedRequest} from "../../lib/isCombinedRequest";
import {isSingleRequest} from "../../lib/isSingleRequest";
import {columns} from "./columns";
import {useMultiplySuccessRequests} from "../../hooks/actions/useMultiplySuccessRequests";
import {useAddCombinedEquipmentRequestAction} from '../../hooks/actions/useAddCombinedEquipmentRequestAction';
import {makeVar, useReactiveVar} from '@apollo/client';

export const combinedRequestsStateVar = makeVar<{ [key: string]: boolean }>({});


type Props = {
    rows: RequestEntity[];
    loading: boolean;
    allCount: number;
    count: number;
    selected: GridRowId[];
    handleSelectionChange: (newSelection: GridRowId[]) => void;
    contextMenuHandler: (event: MouseEvent) => void;
    contextMenuCloseHandler: () => void;
    handleSortModelChange: (model: GridSortModel) => void;
    handlePageChange: (page: number) => void;
    handlePageSizeChange: (pageSize: number) => void;
    refetchData: () => void;
    contextRow: RequestEntity | null;
    contextMenu: { mouseX: number; mouseY: number } | null;
    pageSize: number;
    page: number;
    combinedRequestsLength: number
}

export const RequestTable: FC<Props> = (props) => {
    const {
        rows,
        loading,
        allCount,
        count,
        handleSelectionChange,
        contextMenuHandler,
        contextMenuCloseHandler,
        handleSortModelChange,
        handlePageChange,
        handlePageSizeChange,
        contextRow,
        contextMenu,
        pageSize,
        page,
        combinedRequestsLength
    } = props;
    const combinedRequestsState = useReactiveVar(combinedRequestsStateVar);

    const {onCreateCombinedEquipmentRequestAction} = useCreateCombinedEquipmentRequestAction()
    const {onAddToCombinedEquipmentRequestAction} = useAddCombinedEquipmentRequestAction()
    const [groupedRequests, setGroupedRequests] = useState<RequestEntity[]>([]);
    const [selected, setSelected] = useState<GridRowId[]>([]);

    const handleCheckboxChange = (id: GridRowId) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected((prevSelected) =>
            event.target.checked
                ? [...prevSelected, id]
                : prevSelected.filter((selectedId) => selectedId !== id)
        );
    };

    const {handleEdit} = useEditRequestAction();
    const {handleEditCombineRequest} = useEditCombineRequest()
    const {handleDelete} = useDeleteRequestAction()
    const {handleDeleteCombineRequest} = useDeleteCombineRequest()
    const {handleView} = useViewRequestAction()
    const {handleViewCombineRequest} = useViewCombineRequest()
    const {multiplySuccessRequests} = useMultiplySuccessRequests()


    const toggleGroup = (groupId: string) => {
        combinedRequestsStateVar({
            ...combinedRequestsState,
            [groupId]: !combinedRequestsState[groupId],
        });
    };

    useEffect(() => {
        setGroupedRequests(
            (rows || [])
                .map((row) => {
                        if (isCombinedRequest(row)) {
                            const isOpen = combinedRequestsState[row.id] || false;
                            return {...row, isOpen};
                        }
                        return row
                    }
                )
        )
    }, [rows, combinedRequestsState]);

    const equipmentsRequestColumns = columns({
        toggleGroup,
        selected,
        handleCheckboxChange
    });


    const rowsData = groupedRequests.flatMap((group) => {
            if (isCombinedRequest(group) && group?.childRequests && group?.childRequests?.length > 0) {
                return [
                    group,
                    ...((group.isOpen) ? group.childRequests.map((req, index) => ({
                        ...req,
                        isInCombinedRequest: true,
                        indexNumber: index + 1
                    })) : []),
                ];
            }

            return group;
        }
    );

    const getRowClassName = (params) => {
        const isGroupRow = isCombinedRequest(params.row);
        const isChildRow = params.row.isInCombinedRequest;
        let classes = '';

        if (isGroupRow && params.row.isOpen) {
            classes = 'group-row';
        }

        if (isChildRow) {
            classes = 'child-row';
        }

        if (params.row.id === contextRow?.id && contextMenu !== null) {
            classes += ' isOpenContextMenu';
        }

        return classes;
    };

    return (
        <div style={{height: '100%', width: '100%'}}>
            <StyledDataGrid
                rows={rowsData}
                columns={equipmentsRequestColumns}
                getRowId={(row) => row.id}
                disableSelectionOnClick
                rowBuffer={50}
                getRowClassName={getRowClassName}
                className={tableClasses.root}
                rowHeight={50}

                loading={loading || !rows}
                onRowDoubleClick={(params) => isSingleRequest(params.row) ? handleView(params.row as EquipmentRequestEntity) : handleViewCombineRequest(params.row as CombinedEquipmentRequestEntity)}
                sx={{
                    "& .isOpenContextMenu": {
                        bgcolor: "action.hover"
                    }
                }}
                components={{
                    Footer: Footer,
                    LoadingOverlay: LinearProgress,
                    NoResultsOverlay: () => {
                        return <Box sx={{
                            display: "flex",
                            bgcolor: "background.default",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}/>;
                    },
                    NoRowsOverlay: () => {
                        return <Box sx={{
                            display: "flex",
                            bgcolor: "background.default",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}/>;
                    }

                }}
                componentsProps={{
                    footer: {
                        selected,
                        combinedRequestsLength: combinedRequestsLength,
                        selectedAvailable: selected.map((id) => {
                            const row = rows.find((row) => row.id === id) as EquipmentRequestEntity;
                            if (row?.requestStatus === EquipmentRequestStatusEnum.Submitted) {
                                return row.id
                            } else {
                                return null
                            }
                        }).filter((id) => id !== null).length,
                        onCreateCombinedEquipmentRequest: () => {
                            onCreateCombinedEquipmentRequestAction({
                                equipments: rows.filter(row => selected.includes(row.id)) as EquipmentRequestEntity[],
                                onSuccess: () => {
                                    setSelected([])
                                }
                            })
                        },
                        onAddToCombinedEquipmentRequest: () => {
                            onAddToCombinedEquipmentRequestAction({
                                equipments: rows.filter(row => selected.includes(row.id)) as EquipmentRequestEntity[],
                                onSuccess: () => {
                                    setSelected([])
                                },
                                open: true
                            })
                        },
                        onTakeToWorkHandler: () => multiplySuccessRequests({
                            ids: selected.map((id) => {
                                const row = rows.find((row) => row.id === id) as EquipmentRequestEntity;
                                if (row?.requestStatus === EquipmentRequestStatusEnum.Submitted) {
                                    return row.id
                                } else {
                                    return null
                                }
                            }).filter((id) => id !== null),
                            onSuccess: () => {
                                setSelected([])
                            },
                        }),
                        isDisabled: selected.map((id) => {
                            const row = rows.find((row) => row.id === id) as EquipmentRequestEntity;
                            if (row?.requestStatus === EquipmentRequestStatusEnum.Submitted) {
                                return row.id
                            } else {
                                return null
                            }
                        }).filter((id) => id !== null).length === 0,
                    },
                    row: {
                        onContextMenu: contextMenuHandler,
                        style: {cursor: "context-menu"}
                    },
                }}
                selectionModel={selected}
                sortingMode='server'
                onSortModelChange={handleSortModelChange}
                paginationMode='server'
                rowCount={count}
                page={page}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={[5, 10, 50, 100]}
            />
            {/*<Menu*/}
            {/*    open={contextMenu !== null}*/}
            {/*    onClose={contextMenuCloseHandler}*/}
            {/*    anchorReference='anchorPosition'*/}
            {/*    anchorPosition={*/}
            {/*        contextMenu !== null*/}
            {/*            ? {top: contextMenu.mouseY, left: contextMenu.mouseX}*/}
            {/*            : undefined*/}
            {/*    }*/}
            {/*    elevation={22}*/}
            {/*    sx={{*/}
            {/*        maxWidth: 300,*/}
            {/*        "& .MuiListItemText-root .MuiTypography-root": {*/}
            {/*            textOverflow: "ellipsis",*/}
            {/*            overflow: "hidden",*/}
            {/*            width: "100%",*/}
            {/*            display: "flow-root"*/}
            {/*        }*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <MenuItem*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}

            {/*    >*/}
            {/*        <ListItemIcon>*/}
            {/*            <Iconify icon={"mdi:show-outline"}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Просмотр</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*    <MenuItem*/}
            {/*        onClick={() => {*/}
            {/*            isSingleRequest(contextRow) && handleView(contextRow as EquipmentRequestEntity);*/}
            {/*            isCombinedRequest(contextRow) && handleViewCombineRequest(contextRow as CombinedEquipmentRequestEntity);*/}
            {/*        }}*/}

            {/*    >*/}
            {/*        <ListItemIcon>*/}
            {/*            <Iconify icon={"material-symbols:edit-outline"}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Изменить</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*    <MenuItem*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}

            {/*    >*/}
            {/*        <ListItemIcon>*/}
            {/*            <Iconify icon={"material-symbols:delete-outline-sharp"}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Удалить</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*    <Divider/>*/}
            {/*    <MenuItem*/}
            {/*        dense*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}
            {/*    >*/}

            {/*        <ListItemIcon>*/}
            {/*            <Iconify icon={"tabler:status-change"}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Переместить</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*    <MenuItem*/}
            {/*        dense*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}
            {/*    >*/}

            {/*        <ListItemIcon>*/}
            {/*            <Iconify icon={"carbon:category"}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Изменить категорию</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*    <MenuItem*/}
            {/*        dense*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}
            {/*    >*/}

            {/*        <ListItemIcon>*/}
            {/*            <Iconify icon={"carbon:category"}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Убрать из группы заявок</ListItemText>*/}
            {/*    </MenuItem>*/}
            {/*    <MenuItem*/}
            {/*        dense*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <ListItemIcon>*/}
            {/*            <Iconify icon={"carbon:category"}/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText>Добавить заявку в группу</ListItemText>*/}
            {/*    </MenuItem>*/}

            {/*    <Divider/>*/}
            {/*</Menu>*/}
        </div>
    );
};


const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .group-row': {
        backgroundColor: darken(theme.palette.background.paper, .01),
    },
    '& .child-row': {
        backgroundColor: darken(theme.palette.background.paper, .02),
    },
    '& .MuiDataGrid-row': {
        position: 'relative',
    },
    '& .MuiDataGrid-cell': {
        position: 'relative',
    },
    '& .row-actions': {
        visibility: 'hidden',
        position: 'absolute',
        right: theme.spacing(2),
        left: theme.spacing(2),
        top: '50%',
        transform: 'translateY(-50%)',
        width: `calc(100% - ${theme.spacing(4)})`,
    },
    '& .MuiDataGrid-row:hover .row-actions': {
        visibility: 'visible',
    },
}));

