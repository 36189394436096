import {RequestEntity} from "../../../../types/RequestEntity";
import {isCombinedRequest} from "../../../../lib/isCombinedRequest";
import {isSingleRequest} from "../../../../lib/isSingleRequest";
import {
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum,
    useEquipmentRequestCreateHistoryRecordMutation,
    useUpdateCombinedEquipmentRequestMutation,
    useUpdateEquipmentRequestMutation
} from "graphql/graphQlApiHooks";
import React, {useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import StatusSelect from "../../../StatusSelect";
import {useSuppliedRequestAction} from "../../../../hooks/actions/useSuppliedRequestAction";
import {getLabelStatusRequest} from "../../../../lib/getLabelStatusRequest";
import {useRejectRequestAction} from "../../../../hooks/actions/useRejectRequestAction";
import {LoadingButton} from "@mui/lab";

export const RequestStatusCellRenderer = ({equipmentRequestEntity, disableEdit}: {
    equipmentRequestEntity: RequestEntity,
    disableEdit?: boolean
}) => {
    const [loading, setLoading ] = useState(false);
    const [status, setStatus] = useState(equipmentRequestEntity?.requestStatus);
    const {onSuppliedDialogHandler} = useSuppliedRequestAction();
    const [openWarning, setOpenWarning] = useState(false);
    const [nextStatus, setNextStatus] = useState<EquipmentRequestStatusEnum | null>(null);
    const {onRejectReasonDialogHandler} = useRejectRequestAction();
    const [comment, setComment] = useState('');

    const [equipmentRequestCreateHistoryRecord] = useEquipmentRequestCreateHistoryRecordMutation();

    const [statusOptions, setStatusOptions] = useState<EquipmentRequestStatusEnum[]>([
        EquipmentRequestStatusEnum.Submitted,
        EquipmentRequestStatusEnum.Pending,
        EquipmentRequestStatusEnum.DocumentPackageFormation,
        EquipmentRequestStatusEnum.ContractingServiceAndPlanningAndEconomicDepartmentApproval,
        EquipmentRequestStatusEnum.LotFormation,
        EquipmentRequestStatusEnum.Auction,
        EquipmentRequestStatusEnum.Acceptance,
        EquipmentRequestStatusEnum.EquipmentAccepted
    ]);

    useEffect(() => {
        setStatus(equipmentRequestEntity?.requestStatus);
        if (isSingleRequest(equipmentRequestEntity)) {
            setStatusOptions(prev => Array.from(new Set([...prev, EquipmentRequestStatusEnum.Rejected])));
        }
        if (isCombinedRequest(equipmentRequestEntity)) {
            if (
                equipmentRequestEntity?.requestStatus === EquipmentRequestStatusEnum.Acceptance
                || equipmentRequestEntity?.requestStatus === EquipmentRequestStatusEnum.EquipmentAccepted
            ) {
                setStatusOptions([
                    EquipmentRequestStatusEnum.Acceptance,
                    EquipmentRequestStatusEnum.EquipmentAccepted
                ]);
            }
        }
    }, [equipmentRequestEntity]);

    const [updateCombinedEquipmentRequest] = useUpdateCombinedEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests'],
    });

    const [updateEquipmentRequest] = useUpdateEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests']
    });


    const confirmStatusChange = (newStatus: EquipmentRequestStatusEnum) => {
        setLoading(true)

        const updateVariables = {
            variables: {
                input: {
                    id: equipmentRequestEntity.id,
                    requestStatus: newStatus,
                },
            },
        };

        if (isCombinedRequest(equipmentRequestEntity)) {
            updateCombinedEquipmentRequest(updateVariables).then(() => {
                equipmentRequestCreateHistoryRecord({
                    variables: {
                        input: {
                            requestStatus: newStatus,
                            combinedEquipmentRequestId: equipmentRequestEntity.id,
                            message: comment
                        }
                    }
                }).then(() => {
                    setStatus(newStatus);
                    setComment('');
                    setOpenWarning(false)
                }).finally(()=>{
                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                })
            }).catch(() => {
                setLoading(false)
            });
        } else if (isSingleRequest(equipmentRequestEntity)) {
            updateEquipmentRequest({
                ...updateVariables,
                variables: {
                    ...updateVariables.variables,
                    input: {
                        ...updateVariables.variables.input,
                        count: parseInt(equipmentRequestEntity.count),
                        equipmentTypeId: equipmentRequestEntity.equipmentTypeObject.id,
                        name: equipmentRequestEntity.name,
                        sportObjectId: equipmentRequestEntity.sportObject.id,
                    },
                },
            }).then(() => {
                equipmentRequestCreateHistoryRecord({
                    variables: {
                        input: {
                            requestStatus: newStatus,
                            equipmentRequestId: equipmentRequestEntity.id,
                            message: comment
                        }
                    }
                }).then(() => {
                    setStatus(newStatus);
                    setComment('');
                    setOpenWarning(false)
                }).finally(()=>{
                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                })
            }).catch(() => {
                setLoading(false)
            })
        }
    };

    const onChangeStatus = (newStatus: EquipmentRequestStatusEnum) => {
        setNextStatus(newStatus);
        setOpenWarning(true);
    };

    const handleCloseWarning = (confirm: boolean) => {
        if (confirm && nextStatus) {
            confirmStatusChange(nextStatus);
        } else {
            setOpenWarning(false);
            setNextStatus(null);
            setComment('');
        }
    };

    const statuses = statusOptions.map((value) => ({
        label: getLabelStatusRequest(value),
        value: value
    }));

    const warningMessage = (() => {
        switch (nextStatus) {
            case EquipmentRequestStatusEnum.Rejected:
                return "Вы собираетесь отклонить заявку. Это действие нельзя будет отменить.";
            case EquipmentRequestStatusEnum.Acceptance:
                return <div>
                    {`Установка статуса `}
                    <strong>{getLabelStatusRequest(nextStatus)}</strong>{` является необратимым действием.`}
                    <br/>
                    {`Вернуться на предыдущие шаги будет невозможно.`}
                </div>;
            case EquipmentRequestStatusEnum.EquipmentAccepted:
                return <div>
                    {`Установка статуса `}
                    <strong>{getLabelStatusRequest(nextStatus)}</strong>{` является необратимым действием.`}
                    <br/>
                    {`Дальнейшие изменения заявки будут недоступны.`}
                </div>
            default:
                return <div>
                    Вы уверены, что хотите изменить статус заявки?<br/>
                    {`Текущий статус: `} <strong>{getLabelStatusRequest(status)}</strong>, {`новый статус: `}
                    <strong>{getLabelStatusRequest(nextStatus)}</strong>
                </div>;
        }
    })();

    return (
        <>
            <Dialog open={openWarning} onClose={() => handleCloseWarning(false)}>
                <DialogTitle>Изменение статуса заявки</DialogTitle>
                <DialogContent>
                    {
                        (nextStatus === EquipmentRequestStatusEnum.Rejected
                            || nextStatus === EquipmentRequestStatusEnum.Acceptance
                            || nextStatus === EquipmentRequestStatusEnum.EquipmentAccepted)
                            ? <Alert variant="standard" severity="error">
                                <AlertTitle>Внимание!</AlertTitle>
                                {warningMessage}
                            </Alert>
                            : <Alert variant="standard" severity="info">
                                {warningMessage}
                            </Alert>
                    }

                    <Box mt={2}>
                        <Typography variant={'body2'} mb={2}>
                            Для подтверждения изменения статуса заявки, при необходимости добавьте комментарий и нажмите
                            кнопку "Подтвердить".
                        </Typography>
                        <TextField
                            multiline
                            rows={4}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            fullWidth
                            size={'small'}
                            label='Комментарий'
                            placeholder={`Например: Заявка согласована и передана дальше, для формирования пакета документов.`}
                            variant={"filled"}
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    fontSize: 12,
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseWarning(false)} color="primary">Отмена</Button>
                    <LoadingButton loading={loading} onClick={() => handleCloseWarning(true)} color="secondary" disabled={loading}>Подтвердить</LoadingButton>
                </DialogActions>
            </Dialog>

            {(isCombinedRequest(equipmentRequestEntity) || (isSingleRequest(equipmentRequestEntity) && !equipmentRequestEntity.isInCombinedRequest)) &&
                <Box sx={{
                    width: '100%',
                    '& .MuiFilledInput-root': {transform: 'scale(.8)', transformOrigin: 'left center'}
                }}>
                    <StatusSelect
                        value={status}
                        onChange={({target: {value}}) => {
                            if (value === EquipmentRequestStatusEnum.Rejected) {
                                onRejectReasonDialogHandler(equipmentRequestEntity as EquipmentRequestEntity);
                            } else {
                                onChangeStatus(value as EquipmentRequestStatusEnum);
                            }
                        }}
                        disabled={
                            status === EquipmentRequestStatusEnum.Supplied
                            || status === EquipmentRequestStatusEnum.EquipmentAccepted
                            || status === EquipmentRequestStatusEnum.Rejected
                            || loading
                            || disableEdit
                        }
                        loading={loading}
                        statuses={statuses}
                    />
                </Box>
            }

            {(
                    isSingleRequest(equipmentRequestEntity)
                    && equipmentRequestEntity?.isInCombinedRequest
                    && (
                        equipmentRequestEntity.parentCombinedRequest?.requestStatus === EquipmentRequestStatusEnum.Acceptance
                        || equipmentRequestEntity.parentCombinedRequest?.requestStatus === EquipmentRequestStatusEnum.EquipmentAccepted
                    )
                    && equipmentRequestEntity?.requestStatus !== EquipmentRequestStatusEnum.EquipmentAccepted
                ) &&
                <Box sx={{
                    width: '100%',
                    '& .MuiFilledInput-root': {transform: 'scale(.8)', transformOrigin: 'left center'}
                }}>
                    <Button size="small" onClick={() => onSuppliedDialogHandler(equipmentRequestEntity)}>
                        Принять оборудование
                    </Button>
                </Box>
            }
        </>
    );
};
